var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-list',_vm._g(_vm._b({attrs:{"columns":_vm.columns,"items":_vm.supplies,"loading":_vm.loading},scopedSlots:_vm._u([{key:"cell.photo",fn:function(ref){
var item = ref.item;
return [_c('x-img',{attrs:{"src":item.mainImage}})]}},{key:"cell.name",fn:function(ref){
var item = ref.item;
return [_c('x-link',{attrs:{"to":_vm.getSupplyLocation(item)},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"d-flex gray-darker--text mt-1"},[_vm._v(_vm._s(_vm.$t('Vendor code'))+": "+_vm._s(item.vendor_code))])]}},{key:"cell.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" 1 2 3 4 5 6 7 8"),_c('br'),_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"row.bottom",fn:function(ref){
var item = ref.item;
return [_c('x-divider',{staticClass:"my-4"}),_c('div',{staticClass:"d-flex"},[_c('x-link',{staticClass:"ml-auto",attrs:{"to":_vm.getSupplyLocation(item)}},[_vm._v(" "+_vm._s(_vm.$t('Details'))+" > ")])],1)]}}])},'x-data-list',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }








































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { RouteName } from '@/router/route-name'
import { XDataColumn } from '@/types'
import { formatCurrency, formatCount, formatDateTime } from '@/utils/formatters'

import { useI18n } from '@/utils/useI18n'

import XDataList from '@/components/ui/XDataList.vue'
import SalesOn from '@/views/MySupplies/SalesOn.vue'

import { DATE_DEFAULT, DATE_TIME_DEFAULT } from '@/constants/formats'

const CURRENCY_CODE = '₽'

export default defineComponent({
  inheritAttrs: false,

  components: { SalesOn, XDataList },

  props: {
    supplies: {
      type: Array as PropType<any[]>
    },
    loading: Boolean
  },
  setup () {
    const i18n = useI18n()

    const columns = computed<XDataColumn<any>[]>(() => {
      return [
        {
          prop: 'photo',
          name: i18n.t('Photo'),
          minWidth: 82
        },
        {
          prop: 'name',
          name: i18n.t('Offer name'),
          minWidth: 140
        },
        {
          prop: 'id',
          name: i18n.t('Supply ID'),
          minWidth: 140
        },
        {
          prop: 'created',
          name: i18n.t('Created at'),
          minWidth: 115,
          formatter: (item) => formatDateTime(item.created, DATE_TIME_DEFAULT)
        },
        {
          prop: 'deliveryDate',
          name: i18n.t('Departure date'),
          minWidth: 115,
          formatter: (item) => formatDateTime(item.departureDate, DATE_DEFAULT)
        },
        {
          prop: 'amount',
          name: i18n.t('Amount, pcs'),
          minWidth: 82,
          formatter: (item) => formatCount(item.amount)
        },
        {
          prop: 'price',
          name: i18n.t('Offer price', [CURRENCY_CODE]),
          minWidth: 82,
          formatter: item => formatCurrency(item.price)
        },
        {
          prop: 'status',
          name: i18n.t('Supply status'),
          minWidth: 82
        }
      ]
    })

    const getSupplyLocation = (supply: any) => ({
      name: RouteName.SUPPLY_REVIEW,
      params: { supplyId: String(supply.id) }
    })

    return {
      columns,
      getSupplyLocation
    }
  }
})

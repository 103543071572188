











































import Vue from 'vue'
import XDatePicker from '@/components/ui/XDatePicker.vue'
import STATUSES from '@/dictionaries/supplyRequestStatuses'
import XSwitcher from '@/components/ui/XSwitcher.vue'
import SuppliesList from '@/views/MySupplies/SuppliesList.vue'
import supplies from '@/mock/supplies'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  name: 'MySupplies',

  components: { SuppliesList, XSwitcher, XDatePicker },

  data: () => ({
    supplies,

    dateCreated: {
      start: new Date('2021-02-21T07:31:49.290Z').getTime(),
      end: Date.now() - 10000000000
    },
    dateDeparture: {
      start: new Date('2021-03-20T07:31:49.290Z').getTime(),
      end: Date.now()
    },

    search: '',

    statuses: STATUSES,
    selectedStatuses: [],

    totalCount: 3,

    isOnSaleOnly: false
  }),

  computed: {
    breadcrumbs () {
      return [{
        text: this.$t('My supplies'),
        to: { name: RouteName.SUPPLIES }
      }]
    }
  },

  methods: {
    onSearch () {
      console.log(this.search)
    }
  }
})

import { render, staticRenderFns } from "./MySupplies.vue?vue&type=template&id=60a07dbe&scoped=true&"
import script from "./MySupplies.vue?vue&type=script&lang=ts&"
export * from "./MySupplies.vue?vue&type=script&lang=ts&"
import style0 from "./MySupplies.vue?vue&type=style&index=0&id=60a07dbe&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a07dbe",
  null
  
)

export default component.exports